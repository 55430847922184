(<template>
  <header class="s-header">
    <router-link :to="$makeRoute({name: 'ServerHome'})"
                 class="s-header__logo" />
    <router-link :to="$makeRoute({name: 'ServerHome'})"
                 class="s-header__nav-link s-header__logo-link">{{ $gettext('Home') }}</router-link>
    <header-nav-dropdown :dropdown-subtitle="interpretationDropdownSubtitle"
                         :links="interpretationDropdownLinks"
                         class="s-header__nav-dropdown" />
    <router-link v-if="userIsSeller"
                 :to="projectsLink"
                 class="s-header__nav-link">{{ $pgettext('translation', 'Translation') }}</router-link>
    <p v-if="$isGodmode()"
       class="sk-btn sk-btn--white s-header__godmode-btn">{{ $gettext('Admin mode') }}</p>
    <div class="s-header__account-cont">
      <div class="s-header__invitations-cont">
        <router-link :to="jobInvitationsPageLink"
                     :class="{'new-job-invitation': newInvitation}"
                     class="s-header__link bell-icon">{{ $gettext('Invitations') }}</router-link>
      </div>
      <language-selector v-if="!isMobileView" />
      <a :href="'https://support.salita.no/collection/362-tolk'"
         target="_blank"
         class="s-header__link help-link">{{ $gettext('Help') }}</a>
      <button ref="accountBtn"
              :class="{'header__account-btn--active': openedSubnav}"
              class="header__account-btn"
              @click.stop="toggleSubnav">
        <tt-avatar :own="true"
                   class="s-header__account-avatar" />
      </button>
      <nav v-if="openedSubnav"
           class="header__subnav">
        <div>
          <router-link :to="profileLink"
                       class="subnav-profile-link">
            <tt-avatar :own="true"
                       class="s-header__nav-avatar s-header__nav-avatar--subnav" />
            <div class="subnav-profile-link__desription">
              <p class="subnav-profile-link__name">{{ fullName }}</p>
              <p class="subnav-profile-link__enterprise-name">{{ $gettext('View profile') }}</p>
            </div>
            <div class="subnav-link__arrow-icon"></div>
          </router-link>
        </div>
        <template v-for="(menu,index) in rightMenu">
          <router-link v-if="menu.type === 'router-link' && menu.condition"
                       :key="index"
                       :to="menu.action"
                       :class="menu.classNames">
            <tt-avatar v-if="menu.hasAvatar"
                       :own="true"
                       class="s-header__nav-avatar" />
            {{ menu.name }}</router-link>
          <div v-else-if="menu.type === 'lang-selector' && menu.condition"
               :key="index">
            <language-selector />
          </div>
          <button v-else-if="menu.type === 'button' && menu.condition"
                  :key="'btn-' + index"
                  :class="menu.classNames"
                  @click="menu.action">{{ menu.name }}</button>
          <a v-else-if="menu.type === 'link' && menu.condition"
             :key="'link-' + index"
             :class="menu.classNames"
             :href="menu.href">{{ menu.name }}</a>
        </template>
        <div class="header__subnav-footer subnav-footer">
          <div class="subnav-footer__external-links">
            <a target="_blank"
               :href="privacyLink"
               class="subnav-footer__external-link">{{ $gettext('Privacy') }}</a>
            <a target="_blank"
               :href="termsLink"
               class="subnav-footer__external-link">{{ $gettext('Terms & Conditions') }}</a>
          </div>
          <div class="subnav-footer__social">
            <p>{{ copyright }}</p>
            <div>
              <a class="subnav-footer__icon-link subnav-footer__icon-link--twitter"
                 target="_blank"
                 :href="twitterLink"></a>
              <a class="subnav-footer__icon-link subnav-footer__icon-link--facebook"
                 target="_blank"
                 :href="facebookLink"></a>
              <a class="subnav-footer__icon-link subnav-footer__icon-link--linkedin"
                 target="_blank"
                 :href="linkedinLink"></a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import LanguageSelector from '@/components/dashboard_components/LanguageSelector';
  import HeaderNavDropdown from '@/components/shared_components/HeaderNavDropdown';

  export default {
    components: {
      'header-nav-dropdown': HeaderNavDropdown,
      'language-selector': LanguageSelector,
    },
    data() {
      return {
        openedSubnav: false,
        clientWidth: 768
      };
    },
    computed: {
      ...mapState('s_DashboardStore', {
        newInvitation: (state) => state.newInvitation
      }),
      ...mapGetters('UserInfoStore', [
        'fullName',
        'userUid',
        'userIsInterpreter',
        'userIsSeller',
        'userHasInterpretation'
      ]),
      ...mapGetters('FeatureFlagsStore', ['enableNewHeaderForSupplier']),
      privacyLink() { return this.$getString('domainData', 'privacyLink'); },
      facebookLink() { return this.$getString('social', 'facebookLink'); },
      twitterLink() { return this.$getString('social', 'twitterLink'); },
      linkedinLink() { return this.$getString('social', 'linkedinLink'); },
      termsLink() { return this.$getString('domainData', 'termLink'); },
      copyright() { return this.$getString('footer', 'copyright'); },
      rightMenu() {
        return [
          {
            name: this.$gettext('My interpreter ID'),
            type: 'button',
            classNames: 'header__subnav-link header__subnav-link--interpreter-id',
            action: this.openInterpreterIdModal,
            condition: true
          },
          {
            name: this.$gettext('Documents'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--documents',
            action: this.$makeRoute({name: 'ServerDocuments'}),
            condition: true
          },
          {
            name: this.$gettext('Payout preferences'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--payout-preferences',
            action: this.$makeRoute({name: 'ServerPayoutsAll'}),
            condition: true
          },
          {
            name: this.$gettext('Change password'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--password',
            action: this.$makeRoute({name: 'ServerChangePassword'}),
            condition: true
          },
          {
            name: this.$gettext('Settings'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--settings',
            action: this.settingsLink,
            condition: true
          },
          {
            name: this.$gettext('Accessibility'),
            type: 'button',
            classNames: 'header__subnav-link header__subnav-link--accessibility',
            action: this.openAccessibilityModal,
            condition: true
          },
          {
            name: this.$gettext('Video test'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--video-test',
            action: this.$makeRoute({name: 'ServerTestVideo'}),
            condition: true
          },
          {
            name: '',
            type: 'lang-selector',
            classNames: '',
            condition: this.isMobileView
          },
          {
            name: this.$gettext('Help'),
            type: 'link',
            classNames: 'header__subnav-link header__subnav-link--help-link',
            href: 'https://support.salita.no/collection/380-tolkebruker',
            target: '_blank',
            condition: this.isMobileView
          },
          {
            name: this.$gettext('Sign out'),
            type: 'button',
            classNames: 'header__subnav-link header__subnav-link--sign-out',
            action: this.logOut,
            condition: true
          }
        ];
      },
      interpretationDropdownLinks() {
        return [
          {
            text: this.$gettext('Interpretation assignments'),
            type: 'router-link',
            iconClass: 'interpretation-assignments-icon',
            action: this.assignmentsLink,
            condition: true
          },
          {
            text: this.$gettext('Calendar'),
            type: 'router-link',
            iconClass: 'calendar-icon',
            action: this.calendarLink,
            condition: true
          },
        ];
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'ServerCalendar',
          query: {
            view: 'week',
            date: this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      projectsLink() {
        return this.$makeRoute({
          name: 'ServerAllProjects',
          query: {
            availableStatus: 'new',
            acceptedStatus: 'active'
          }
        });
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'ServerAllAssignments',
          query: {
            view: 'current',
            sortConfirmedBy: 1,
            sortAvailableBy: 1,
            pageConfirmedBy: 1,
            pageAvailableBy: 1
          }
        });
      },
      settingsLink() {
        return this.isMobileView ? this.$makeRoute({name: 'ServerPreferences'}) : this.$makeRoute({name: 'ServerPreferencesAvailability'});
      },
      profileLink() {
        return this.$makeRoute({name: 'ServerProfileEdit', params: {id: this.userUid}});
      },
      jobInvitationsPageLink() {
        return this.$makeRoute({name: 'ServerAllInvitations'});
      },
      isMobileView() { return this.clientWidth < 768; },
      interpretationDropdownSubtitle() { return this.$gettext('Interpretation'); }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      openAccessibilityModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'accessibility-modal',
        });
      },
      toggleSubnav(e) {
        this.toggleAbsoluteBlocks();
        this.openedSubnav = !this.openedSubnav;
        if (e.target.className.indexOf('s-header__account-') != -1) {
          this.$refs.accountBtn.blur();
        }
      },
      toggleAbsoluteBlocks(e) {
        if (e && (e.target.className.indexOf('s-header__account-') != -1 && e.target.className.indexOf('s-header__notifications-') != -1)) {
          return;
        }
        if (e && e.target.className.indexOf('language-dropdown') != -1) {
          return;
        }
        this.openedSubnav = false;
      },
      setClientWidth() {
        this.clientWidth = document.documentElement.clientWidth;
      },
      logOut() {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          // set cookie for preventing mobile app redirect after clicking on logout button
          this.$cookieManager.setCookie('preventMobileAppRedirect', true, {path: '/', expires: 2592000});
          setTimeout(() => {
            this.$goToExternalLoginPage();
          }, 0);
        });
      },
      openInterpreterIdModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'interpreter-id-modal',
          width: 333
        });
      }
    },
    mounted() {
      this.setClientWidth();
      document.addEventListener('click', this.toggleAbsoluteBlocks);
      window.addEventListener('resize', this.setClientWidth);
    }
  };
</script>

<style scoped>
  .s-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
  }

  .input-focused .s-header {
    position: absolute;
  }

  .s-header__logo {
    display: block;
    width: 60px;
    background-image: url(~@assets/imgs/logos/single_logo_red.svg);
    background-image: var(--image-single-logo);
    background-position: 50% 50%;
    background-size: 30px auto;
    background-repeat: no-repeat;
  }

  .s-header__godmode-btn {
    display: inline-block;
    align-self: center;
    width: auto;
    height: 35px;
    margin-left: 10px;
    padding: 0 10px;
  }

  .s-header__nav-link {
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #000;
  }

  .s-header__nav-link:hover,
  .s-header__link:hover {
    background-color: #f4f5f7;
    transition: ease-in-out 0.125s;
  }

  .s-header__link {
    display: inline-block;
    color: #000;
    line-height: 60px;
  }

  .s-header__logo-link {
    display: inline-block;
    padding: 0 10px;
    padding-left: 15px;
    line-height: 60px;
  }

  .s-header__usual-link.router-link-active {
    color: #646d8c;
    color: var(--color-primary);
  }

  .s-header__account-cont {
    display: flex;
    margin-left: auto;
  }

  .s-header__account-avatar {
    width: 30px;
    height: 30px;
  }

  .s-header__invitations-cont {
    position: relative;
    display: flex;
  }

  .bell-icon {
    padding-left: 35px;
    background-image: url(~@assets/imgs/nav_icons/bell_icon.svg);
    background-position: 5% center;
    background-size: 25px auto;
    background-repeat: no-repeat;
  }

  .bell-icon.new-job-invitation::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 25px;
    box-sizing: border-box;
    padding: 3px;
    border-radius: 100%;
    background: var(--color-primary);
  }

  .s-header__subnav-link {
    position: relative;
    display: block;
    width: 100%;
    padding-right: 30px;
    padding-left: 65px;
    background-color: #fff;
    background-repeat: no-repeat;
    line-height: 45px;
    text-align: left;
    white-space: nowrap;
  }

  .s-header__subnav-link:focus,
  .s-header__logo-link:focus {
    z-index: 1;
    outline: 2px solid #3af;
  }

  .s-header__subnav-link:hover,
  .s-header__logo-link:hover {
    background-color: #f4f6f8;
  }

  .s-header__subnav-link:active,
  .s-header__logo-link:active {
    background-color: #e8e9ed;
    outline: none;
  }

  .s-header__subnav-link::after {
    content: '';
    position: absolute;
    right: 10px;
    bottom: 0;
    left: 10px;
    display: block;
    height: 1px;
    background-color: #e4e7ee;
  }

  .s-header__nav-avatar {
    position: absolute;
    top: 7px;
    left: 17px;
    width: 30px;
    height: 30px;
  }

  .s-header__nav-avatar--subnav {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10px;
  }

  .s-header__subnav-link.router-link-active {
    color: #646d8c;
    color: var(--color-primary);
  }

  @media (max-width: 767px) {
    .s-header {
      height: 45px;
      padding-right: 50px;
    }

    .s-header__nav-link,
    .s-header__nav-dropdown {
      display: none;
    }

    .s-header__godmode-btn {
      display: none;
    }

    .s-header__logo {
      flex-shrink: 0;
      background-size: 25px auto;
    }

    .help-link,
    .s-header__invitations-cont {
      display: none;
    }
  }
</style>
