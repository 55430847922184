(<template>
  <section class="mobile-drawer">
    <div class="mobile-drawer__wrapper">
      <div class="mobile-drawer__header">
        <h3>{{ title }}</h3>
        <button class="mobile-drawer__close-btn"
                @click="closeDrawer"></button>
      </div>
      <div v-if="mobileDrawerType === 'menu'"
           class="mobile-drawer__body">
        <router-link v-for="(link, index) in filteredInterpretationLinks"
                     :key="index"
                     :to="link.action"
                     :class="{'favourite-interp-link': link.iconClass === 'favorite-interpreters-icon'}"
                     class="dropdown-list__item dropdown-link">
          <div class="dropdown-link__image"
               :class="link.iconClass"></div>
          <span class="dropdown-link__text">{{ link.text }}</span>
        </router-link>
      </div>
      <div v-if="mobileDrawerType === 'menu'">
        <h3 class="mobile-drawer__subtitle">{{ $pgettext('translate', 'Translation') }}</h3>
        <div class="mobile-drawer__body">
          <router-link v-for="(link, index) in filteredTranslationLinks"
                       :key="index"
                       :to="link.action"
                       :class="{'favourite-interp-link': link.iconClass === 'favorite-interpreters-icon'}"
                       class="dropdown-list__item dropdown-link">
            <div class="dropdown-link__image"
                 :class="link.iconClass"></div>
            <span class="dropdown-link__text">{{ link.text }}</span>
          </router-link>
        </div>
      </div>
      <div v-if="mobileDrawerType === 'book'"
           class="mobile-drawer__body">
        <div v-for="(link, index) in bookLinks"
             :key="index"
             class="menu-list__item menu-link"
             @click="handleLinkClick(link)">
          <div class="menu-link__image"
               :class="link.iconClass"></div>
          <div class="menu-link__inner-wrapper">
            <span class="menu-link__subtitle">{{ link.subtitle }}</span>
            <span class="menu-link__text">{{ link.text }}</span>
          </div>
          <div class="menu-link__arrow-icon"></div>
        </div>
      </div>
    </div>
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import constants from '@/modules/constants';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCanShowStatistics',
        'isEmailVerificationRequired',
        'showPowerBIPage',
        'userIsEnterpriseMember'
      ]),
      mobileDrawerType() { return this.$store.getters.mobileDrawerType; },
      title() {
        if (this.mobileDrawerType === 'menu') return this.$gettext('Interpretation');
        if (this.mobileDrawerType === 'book') return this.$gettext('Book');
      },
      postTranslationLink() { return this.$isGodmode() ? `${constants.CONNECT_PATH?.replace(/\/+$/, '')}/create-translation-order?customeruid=${this.$store.getters['UserInfoStore/userUid']}` : this.$makeRoute({name: 'BuyerPostTranslationProject'}); },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'BuyerAllAssignments',
          query: {
            view: 'current',
            sort: 1,
            page: 1
          }
        });
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'BuyerCalendar',
          query: {
            view: 'week',
            date: this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      capacityLink() {
        return this.$makeRoute({
          name: 'BuyerCapacity',
          query: {
            view: 'day',
            date: this.$moment().format('YYYY-MM-DD'),
            page: 1
          }
        });
      },
      interpretersLink() {
        return this.$makeRoute({
          name: 'BuyerInterpreters',
          query: {
            page: 1,
            availability_date: this.$moment().format('YYYY-MM-DD'),
            langFrom: '3',
            relation: true
          }
        });
      },
      statisticLink() {
        return this.showPowerBIPage
          ? this.$makeRoute({name: 'BuyerPowerBI'})
          : this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'interpretation'}, params: {chartId: 'line_type'}});
      },
      menuLinksInterpretation() {
        return [
          {
            text: this.$gettext('Interpretation assignments'),
            type: 'router-link',
            iconClass: 'interpretation-assignments-icon',
            action: this.assignmentsLink,
            condition: true
          },
          {
            text: this.$gettext('Calendar'),
            type: 'router-link',
            iconClass: 'calendar-icon',
            action: this.calendarLink,
            condition: true
          },
          {
            text: this.$gettext('Interpretation statistics'),
            type: 'router-link',
            iconClass: 'interpretation-statistics-icon',
            action: this.statisticLink,
            condition: this.userCanShowStatistics
          },
          {
            text: this.$gettext('Favorite interpreters'),
            type: 'router-link',
            iconClass: 'favorite-interpreters-icon',
            action: this.interpretersLink,
            condition: true
          },
          {
            text: this.userIsEnterpriseMember ? this.$gettext('Book specific interpreter') : this.$gettext('Book interpreter'),
            type: 'router-link',
            iconClass: 'book-specific-interpreter-icon',
            action: this.capacityLink,
            condition: !this.isEmailVerificationRequired
          }
        ];
      },
      menuLinksTranslation() {
        return [
          {
            text: this.$gettext('Translation assignments'),
            type: 'router-link',
            iconClass: 'translation-assignments-icon',
            action: this.$makeRoute({name: 'BuyerAllProjects'}),
            condition: true
          },
          {
            text: this.$gettext('Translation statistics'),
            type: 'router-link',
            iconClass: 'translation-statistics-icon',
            action: this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'translation'}, params: {chartId: 'line_type'}}),
            condition: this.userCanShowStatistics
          }
        ];
      },
      bookLinks() {
        return [
          {
            subtitle: this.$gettext('Interpreter'),
            text: this.$gettext('Speech Language Interpreter'),
            type: 'router-link',
            iconClass: 'book-interpreter-icon',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'})
          },
          {
            subtitle: this.$gettext('Translator'),
            text: this.$gettext('Documents, files, etc.'),
            type: 'router-link',
            iconClass: 'order-translation-icon',
            action: this.postTranslationLink
          },
          {
            subtitle: this.$gettext('Sign language translator'),
            text: this.$gettext('Gestures, facial expressions and body language'),
            type: 'router-link',
            iconClass: 'book-sing-lang-translator',
            action: this.$makeRoute({name: 'BuyerOtherServiceAssignment', params: {service: 1}})
          },
          {
            subtitle: this.$gettext('Other services'),
            text: this.$gettext('Subtitling, conference interpreting, etc.'),
            type: 'router-link',
            iconClass: 'other-service',
            action: this.$makeRoute({name: 'BuyerOtherServiceAssignment', params: {service: 'other'}})
          }
        ];
      },
      filteredTranslationLinks() { return this.menuLinksTranslation.filter((link) => link.condition); },
      filteredInterpretationLinks() { return this.menuLinksInterpretation.filter((link) => link.condition); }
    },
    methods: {
      closeDrawer() {
        this.$store.commit('toggleMobileDrawer', {force: false, drawerType: ''});
      },
      handleLinkClick(link) {
        const action = typeof link.action === 'function' ? link.action() : link.action;
        typeof action === 'string' ? window.open(action, '_blank') : this.$router.push(action);
      },
    }
  };
</script>

<style scoped>
.mobile-drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #646d8c80;
}

.mobile-drawer__wrapper {
  margin-top: auto;
  margin-bottom: 76px;
  border-radius: 8px 8px 0 0;
  background: #fff;
  box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);
}

.mobile-drawer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 15px;
}

.mobile-drawer__subtitle {
  padding: 8px 16px;
}

.mobile-drawer__body {
  margin: 0 8px 8px;
}

.mobile-drawer__close-btn {
  width: 12px;
  height: 12px;
  background-image: url(~@assets/imgs/salita/dashboard_icons/close_x_mobile.svg);
  background-position: center center;
}
</style>
