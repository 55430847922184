// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/dashboard_icons/arrow_down_white.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/salita/dashboard_icons/arrow_up_white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.book-btn-menu[data-v-5a197a51] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-left: 20px;\n}\n.book-btn[data-v-5a197a51] {\n  height: 35px;\n  padding: 0 30px 0 10px;\n  border-radius: 3px;\n  background-color: var(--color-primary);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: calc(100% - 10px) center;\n  background-size: 19px auto;\n  background-repeat: no-repeat;\n  color: #fff;\n}\n.book-btn--active[data-v-5a197a51] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.menu-list[data-v-5a197a51] {\n  position: absolute;\n  top: 65px;\n  z-index: 3;\n  width: 400px;\n  padding: 8px;\n  border-radius: 8px;\n  background: #fff;\n  -webkit-box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);\n          box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);\n}\n@media (max-width: 767px) {\n.book-btn-menu[data-v-5a197a51] {\n    display: none;\n}\n}\n", ""]);
// Exports
module.exports = exports;
